/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Autocomplete, Button, Divider, Grid, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Fragment, useRef, useState } from "react";
import { EBillingType, ECollectDocType } from "../../Utils/Enums";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Config from "../../Utils/Config";
import moment from "moment";
import CustomDrawer from "../CustomDrawer";
import BillingDocClientData from "../BillingDocuments/BillingDocClientData";
import { postData } from "../../Services/postData";
import { endpoints } from "../../Utils/UrlEnum";
import ListItemBillingDoc from "../BillingDocuments/ListItemBillingDoc";
import ListItemProductLine from "../BillingDocuments/ListItemProductLine";
import { InvoicePanelProps } from "../../Utils/Types";
import { AxiosResponse } from "axios";
import { FormsIds } from "../../Utils/Constants";
import {
  InvoicePanelValidationSchema,
  IssueBillingDocument,
} from "../../Utils/Models";
import { deleteData } from "../../Services/deleteData";
import { updateData } from "../../Services/updateData";
import { calculateTotal } from "../../Utils/Utils";
import useCustomMemo from "../../Hooks/useCustomMemo";

export default function InvoicePanel(props: InvoicePanelProps) {
  const ref: any = useRef();
  const authenticatedUserFullName =
    window.localStorage.getItem("fullName") || "";
  const { getOrderValues, watchOrder, handleSaveOrder, managements } = props;
  const [open, setOpen] = useState(false);
  const {
    register,
    control,
    setValue,
    getValues,
    formState,
    reset,
    handleSubmit,
    watch,
    unregister,
  } = useForm({
    resolver: yupResolver(InvoicePanelValidationSchema),
    defaultValues: {
      issuerName: authenticatedUserFullName,
      referenceDocument: null,
    },
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const currentClient = getOrderValues("client_id");
  const options = Object.values(ECollectDocType);
  const memo = useCustomMemo();
  const defaultManagement = memo?.storage.defaultManagement || null;

  /**
   *
   * @returns
   */
  const shouldRenderInvoice = () => {
    //check if lineChecked is true in at least one line and return true
    return getOrderValues("line_items")?.some((item: any) => item.lineChecked);
  };

  /**
   *
   */
  const addProductToBillingDocument = () => {
    //get all lines that are checked
    const checkedLines = getOrderValues("line_items")?.filter(
      (item: any) => item.lineChecked
    );
    //iterate over all checked lines and add them to the billing document
    const productArray: any[] = [];
    checkedLines?.forEach((line: any) => {
      const product = {
        name: line.name,
        price: line.price,
        quantity: parseFloat(line.quantity) || 0,
        vatPercentage: line.TVA,
        vatIncluded: 1,
        productType: Vocabulary.goods,
        measuringUnit: line.product_id?.oblio_measuring_unit,
        management: line.management,
      };
      productArray.push(product);
      //if product has discount add a new object with the discount
      if (line.discount) {
        productArray.push({
          name: `${Vocabulary.discount} ${line.discount}% ${line.name}`,
          discount: line.discount,
          discountType: "procentual",
        });
      }
    });
    //check if shipping_total is not 0 or empty string or null or undefined
    const shippingTotal = getOrderValues("shipping_total");
    if (
      shippingTotal &&
      shippingTotal !== 0 &&
      shippingTotal !== "" &&
      shippingTotal !== "null" &&
      shippingTotal !== "undefined" &&
      shippingTotal !== "0"
    ) {
      const shipping = {
        name: Vocabulary.shipping,
        price: shippingTotal,
        quantity: 1,
        productType: Vocabulary.service,
      };
      productArray.push(shipping);
    }
    setValue("products", productArray);
    //get default management
    if (defaultManagement) {
      setValue("workStation", defaultManagement?.workStation);
    }
    setClient();
  };

  /**
   *set the client data (use data from billing and if currentClient exist complete missing data with it)
   */
  const setClient = () => {
    const billing = getOrderValues("billing");
    const currentClientName =
      billing.company.length > 0
        ? billing.company
        : `${billing.first_name} ${billing.last_name}`;
    let cif, bank, iban, contactPerson, tradeRegisterNo;
    if (currentClientName) {
      cif = currentClient?.fiscalCode ? currentClient.fiscalCode : null;
      tradeRegisterNo = currentClient?.tradeRegisterNo
        ? currentClient.tradeRegisterNo
        : null;
      bank = currentClient?.bank ? currentClient.bank : null;
      iban = currentClient?.iban ? currentClient.iban : null;
      contactPerson = currentClient?.contactPerson?.name;
    }
    const address = billing?.address_1 ? billing.address_1 : null;
    const county = billing?.county ? billing.county : null;
    const city = billing?.city ? billing.city : null;
    const country = billing?.country ? billing.country : null;
    const phone = billing.phone ? billing.phone : null;
    const email = billing.email ? billing.email : null;
    const updatedClient = {
      name: currentClientName,
      cif: cif,
      rc: tradeRegisterNo,
      address: address,
      state: county,
      city: city,
      country: country,
      phone: phone,
      email: email,
      iban: iban,
      bank: bank,
      contact: contactPerson,
    };
    setValue("client", updatedClient);
    return updatedClient;
  };

  /**
   *
   */
  const renderInvoice = () => {
    const shouldRender = shouldRenderInvoice();
    if (!shouldRender)
      toast.error(Vocabulary.youNeedToSelectAtLeastOneProductToGenerateInvoice);
    else {
      //set collect object id payment methods Numerar la livrare
      const selectedPaymentMethod = getOrderValues("payment_method_title")
        ? getOrderValues("payment_method_title")
        : "";
      if (
        selectedPaymentMethod &&
        selectedPaymentMethod.toUpperCase() === Vocabulary.cash.toUpperCase()
      ) {
        setValue("collect", {
          type: ECollectDocType.Receipt,
        });
      }
      setOpen(true);
      setValue("type", EBillingType.invoice);
      addProductToBillingDocument();
    }
  };

  /**
   *
   */
  const renderNotice = () => {
    const shouldRender = shouldRenderInvoice();
    if (!shouldRender)
      toast.error(Vocabulary.youNeedToSelectAtLeastOneProductToGenerateNotice);
    else {
      setOpen(true);
      setValue("type", EBillingType.notice);
      //detele collect object from
      unregister("collect");
      addProductToBillingDocument();
    }
  };

  /**
   *
   * @returns
   */
  const renderBillingDocs = () => {
    const billingDocs = watchOrder("billingDocuments");
    return billingDocs?.map((doc: any, index: number) => {
      return (
        <ListItemBillingDoc
          handleDeleteBillingDoc={handleDeleteBillingDoc}
          handleCancelBillingDoc={handleCancelBillingDoc}
          handleIssueInvoiceOnNotice={handleIssueInvoiceOnNotice}
          handleIssueCollectInvoice={handleIssueCollectInvoice}
          key={index}
          doc={doc}
        />
      );
    });
  };

  /**
   *
   * @param series
   * @param number
   * @param type
   */
  const handleDeleteBillingDoc = (
    series: string,
    number: string,
    type: string
  ) => {
    const orderId = getOrderValues("_id");
    deleteData(
      `${endpoints.oblio.main}/${orderId}/${series}/${number}/${type}`
    ).then((res: AxiosResponse) => {
      if (res && res.data) {
        handleSaveOrder(res.data);
      }
    });
  };

  /**
   *
   * @param type
   * @param series
   * @param number
   */
  const handleCancelBillingDoc = (
    series: string,
    number: string,
    type: string
  ) => {
    const orderId = getOrderValues("_id");
    updateData(
      `${endpoints.oblio.cancel}/${orderId}/${series}/${number}/${type}`,
      {}
    ).then((res: AxiosResponse) => {
      if (res && res.data) {
        handleSaveOrder(res.data);
      }
    });
  };

  const handleIssueCollectInvoice = (doc: any) => {
    console.log(doc);
  };

  /**
   *
   * @param doc
   */
  const handleIssueInvoiceOnNotice = (doc: any) => {
    setClient();
    const currentOrder = watchOrder();
    const orderId = getOrderValues("_id");
    const model: any = watch();
    model.referenceDocument = {
      seriesName: doc.seriesName,
      number: doc.number,
      type: doc.type,
    };
    model.type = EBillingType.invoice;

    const data = new IssueBillingDocument(model, [], currentOrder);
    postData(`${endpoints.oblio.main}/${orderId}`, data).then(
      (res: AxiosResponse) => {
        if (res && res.data) {
          handleSaveOrder(res.data);
          setOpen(false);
          reset();
        }
      }
    );
  };

  /**
   *
   * @param model
   */
  const issueBillingDocument = (model: any) => {
    const currentOrder = watchOrder();
    //get line items ids that are checked (item.lineChecked) and send them to the api
    const checkedLinesIds = getOrderValues("line_items")
      .filter((item: any) => item.lineChecked)
      .map((item: any) => item.id);
    const orderId = getOrderValues("_id");
    const data = new IssueBillingDocument(model, checkedLinesIds, currentOrder);
    postData(`${endpoints.oblio.main}/${orderId}`, data).then(
      (res: AxiosResponse) => {
        if (res && res.data) {
          handleSaveOrder(res.data);
          setOpen(false);
          reset();
        }
      }
    );
  };

  /**
   *
   * @param errors
   */
  const onErrors = (errors: any) => {
    console.log(errors);
  };

  /**
   *
   * @returns
   */
  const renderBillingDocumentProducts = () => {
    return (
      <Grid
        sx={{
          backgroundColor: "#F8F8F8",
          borderRadius: 2,
        }}
        container
        spacing={1}
      >
        <ListItemProductLine
          name={Vocabulary.fancyName}
          quantity={Vocabulary.quantity}
          priceWithoutTVA={Vocabulary.priveWithTVA}
          price={Vocabulary.value}
          management={Vocabulary.management}
        />
        <Grid item xs={12} sm={12} md={12}>
          <Divider orientation="horizontal" sx={{ width: "100%" }} />
        </Grid>
        {getValues("products")?.map((product: any, index: number) => {
          return (
            <ListItemProductLine
              key={index}
              name={product.name}
              quantity={product.quantity}
              priceWithoutTVA={product.price}
              price={calculateTotal(
                product.price,
                product.quantity
              )?.toString()}
              management={product.management}
              managements={managements}
              index={index}
              control={control}
              discount={product.discount}
              productType={product.productType}
            />
          );
        })}
      </Grid>
    );
  };

  return (
    <Fragment>
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={6} xl={2}>
          <Button
            fullWidth
            startIcon={<Add />}
            onClick={renderInvoice}
            variant="contained"
            sx={{ backgroundColor: "#009892" }}
          >
            {Vocabulary.invoice}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={2}>
          <Button
            fullWidth
            onClick={renderNotice}
            startIcon={<Add />}
            variant="contained"
            sx={{ backgroundColor: "#0086BC" }}
          >
            {Vocabulary.notice}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {renderBillingDocs()}
        </Grid>
      </Grid>
      <CustomDrawer
        open={open}
        handleDrawerClose={() => {
          setOpen(false);
          reset();
        }}
        title={
          getValues("type") === EBillingType.invoice
            ? Vocabulary.issuingInvoice
            : Vocabulary.issuingNotice
        }
        okText={Vocabulary.generate}
        formId={FormsIds.billingDocumentsForm}
      >
        <form
          ref={ref}
          id={FormsIds.billingDocumentsForm}
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleSubmit(issueBillingDocument, onErrors)(e);
          }}
        >
          <Grid container spacing={Config.standardGridSpacing}>
            <BillingDocClientData
              clientData={getValues("client")}
              register={register}
              formState={formState}
            />
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={Config.standardGridSpacing}>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="issueDate"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          {...field}
                          label={Vocabulary.issueDate}
                          sx={{ width: "100%" }}
                          views={["year", "month", "day"]}
                          format={Config.momentEUDateFormat}
                          value={
                            getValues("issueDate")
                              ? moment(getValues("issueDate"))
                              : null
                          }
                          onChange={(date: any) => {
                            field.onChange(
                              moment(date).format(Config.momentUSDateFormat)
                            );
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="dueDate"
                    control={control}
                    defaultValue={null}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          {...field}
                          label={Vocabulary.dueDate}
                          sx={{ width: "100%" }}
                          views={["year", "month", "day"]}
                          format={Config.momentEUDateFormat}
                          value={
                            getValues("dueDate")
                              ? moment(getValues("dueDate"))
                              : null
                          }
                          onChange={(date: any) => {
                            field.onChange(
                              moment(date).format(Config.momentUSDateFormat)
                            );
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    {...register("issuerName")}
                    label={Vocabulary.issuerName}
                    variant="outlined"
                    defaultValue={authenticatedUserFullName}
                    error={typeof formState.errors.issuerName === "object"}
                    helperText={formState.errors.issuerName?.message?.toString()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    {...register("issuerId")}
                    label={Vocabulary.issuerId}
                    variant="outlined"
                    error={typeof formState.errors.issuerId === "object"}
                    helperText={formState.errors.issuerId?.message?.toString()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    {...register("deputyName")}
                    label={Vocabulary.deputyName}
                    variant="outlined"
                    error={typeof formState.errors.deputyName === "object"}
                    helperText={formState.errors.deputyName?.message?.toString()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    {...register("deputyIdentityCard")}
                    label={Vocabulary.deputyIdentityCard}
                    variant="outlined"
                    error={
                      typeof formState.errors.deputyIdentityCard === "object"
                    }
                    helperText={formState.errors.deputyIdentityCard?.message?.toString()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    {...register("noticeNumber")}
                    label={Vocabulary.noticeNumber}
                    variant="outlined"
                    error={typeof formState.errors.noticeNumber === "object"}
                    helperText={formState.errors.noticeNumber?.message?.toString()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    {...register("selesAgent")}
                    label={Vocabulary.selesAgent}
                    variant="outlined"
                    error={typeof formState.errors.selesAgent === "object"}
                    helperText={formState.errors.selesAgent?.message?.toString()}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={Config.standardGridSpacing}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    multiline
                    rows={
                      getValues("type") === EBillingType.invoice ? 7.8 : 11.4
                    }
                    {...register("mentions")}
                    defaultValue={Vocabulary.defaultInvoiceMentions}
                    label={Vocabulary.mentions}
                    variant="outlined"
                    error={typeof formState.errors.mentions === "object"}
                    helperText={formState.errors.mentions?.message?.toString()}
                    fullWidth
                  />
                </Grid>
                {getValues("type") === EBillingType.invoice &&
                  getValues("collect.type") && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        name={`collect.type`}
                        control={control}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            value={field.value || " "}
                            freeSolo={false}
                            disablePortal
                            disableClearable
                            disabled
                            clearOnBlur={true}
                            options={options}
                            isOptionEqualToValue={(option: any, value: any) => {
                              return option.management === value;
                            }}
                            getOptionLabel={(option: any) =>
                              option?.management || option || ""
                            }
                            onChange={(event, value: any) => {
                              field.onChange(value.management);
                            }}
                            renderInput={(params) => (
                              <TextField
                                value={field?.value || " "}
                                error={!!error}
                                helperText={error?.message}
                                label={Vocabulary.collectDocType}
                                {...params}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Divider
                orientation="horizontal"
                textAlign="left"
                sx={{ width: "100%" }}
              >
                {Vocabulary.products}
              </Divider>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {renderBillingDocumentProducts()}
            </Grid>
          </Grid>
        </form>
      </CustomDrawer>
    </Fragment>
  );
}
