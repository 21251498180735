/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  TextField,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Delete } from "@mui/icons-material";
import styles from "../../Styles/order.module.css";
import { EditProduct } from "./EditProduct";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import {
  calcDiscount,
  calcPriceWithoutTVA,
  calcTVAValue,
  calcTVAValueFromPriceWithoutTVA,
  calculatePriceWithTVA,
  calcValue,
  calcValueWithTVA,
} from "../../Utils/Utils";
import { ProductPanelProps } from "../../Utils/Types";
import { Controller } from "react-hook-form";
import { endpoints } from "../../Utils/UrlEnum";
import useCustomMemo from "../../Hooks/useCustomMemo";

export default function ProductPanel(props: ProductPanelProps) {
  const { watch, index, managements } = props;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const memo = useCustomMemo();
  const defaultManagement = memo?.storage.defaultManagement || null;

  /**
   *
   * @param index \
   */
  const deleteLine = (index: any) => {
    const linesCopy = props.getValues("line_items");
    linesCopy.splice(index, 1);
    setValue("line_items", linesCopy);
  };

  /**
   *
   * @param name
   * @param value
   */
  const setValue = (name: string, value: any, shouldBeDirty?: boolean) => {
    props.setValue(name, value, {
      shouldDirty: shouldBeDirty === false ? false : true,
    });
  };

  /**
   *
   */
  useEffect(() => {
    makeSubTotals(false);
  }, []);

  /**
   *
   * @param price
   */
  const calculateSubTotalsBasedOnPrice = (price: any) => {
    //calc price witout TVA
    const priceWithoutTVA = calcPriceWithoutTVA(
      price,
      props.getValues(`line_items[${index}].TVA`)
    );
    setValue(`line_items[${index}].priceWithoutTVA`, priceWithoutTVA);
    //calculate discount value
    setValue(
      `line_items[${index}].discountValue`,
      calcDiscount(
        props.getValues(`line_items[${index}].discount`),
        props.getValues(`line_items[${index}].quantity`),
        priceWithoutTVA
      )
    );
    //calculate subtotal without TVA
    setValue(
      `line_items[${index}].subtotal_tax`,
      calcValue(
        props.getValues(`line_items[${index}].discount`),
        props.getValues(`line_items[${index}].quantity`),
        priceWithoutTVA
      ).toFixed(2)
    );
    //calculate unit TVA
    setValue(
      `line_items[${index}].unitTVA`,
      calcTVAValueFromPriceWithoutTVA(
        priceWithoutTVA,
        props.getValues(`line_items[${index}].TVA`)
      ).toFixed(2)
    );
    //calculate total
    setValue(
      `line_items[${index}].total`,
      calcValueWithTVA(
        calcValue(
          props.getValues(`line_items[${index}].discount`),
          props.getValues(`line_items[${index}].quantity`),
          priceWithoutTVA
        ),
        props.getValues(`line_items[${index}].TVA`)
      ).toFixed(2)
    );
  };

  /**
   * calculates all the totals per line item based on all editable fields.
   */
  const makeSubTotals = (shouldBeDirty?: boolean) => {
    //calculate price with TVA

    setValue(
      `line_items[${index}].price`,
      calculatePriceWithTVA(
        props.getValues(`line_items[${index}].priceWithoutTVA`),
        props.getValues(`line_items[${index}].TVA`)
      ),
      shouldBeDirty
    );

    //calculate discount value
    setValue(
      `line_items[${index}].discountValue`,
      calcDiscount(
        props.getValues(`line_items[${index}].discount`),
        props.getValues(`line_items[${index}].quantity`),
        props.getValues(`line_items[${index}].priceWithoutTVA`)
      ),
      shouldBeDirty
    );
    //calculate subtotal without TVA
    setValue(
      `line_items[${index}].subtotal_tax`,
      calcValue(
        props.getValues(`line_items[${index}].discount`),
        props.getValues(`line_items[${index}].quantity`),
        props.getValues(`line_items[${index}].priceWithoutTVA`)
      ).toFixed(2),
      shouldBeDirty
    );
    //calculate unit TVA
    setValue(
      `line_items[${index}].unitTVA`,
      calcTVAValueFromPriceWithoutTVA(
        props.getValues(`line_items[${index}].priceWithoutTVA`),
        props.getValues(`line_items[${index}].TVA`)
      ).toFixed(2),
      shouldBeDirty
    );
    //calculate total
    setValue(
      `line_items[${index}].total`,
      calcValueWithTVA(
        calcValue(
          props.getValues(`line_items[${index}].discount`),
          props.getValues(`line_items[${index}].quantity`),
          props.getValues(`line_items[${index}].priceWithoutTVA`)
        ),
        props.getValues(`line_items[${index}].TVA`)
      ).toFixed(2),
      shouldBeDirty
    );
  };

  /**
   *
   * @param e
   * @param product
   * @param reason
   * @param details
   */
  const handleProductChange = (
    e: any,
    product: any,
    reason: any,
    details: any
  ) => {
    if (reason === "selectOption") {
      setValue(`line_items[${index}].product.id`, product);
      setValue(`line_items[${index}]`, product);
      setValue(`line_items[${index}].quantity`, 1);
      setValue(`line_items[${index}].discount`, 0);
      setValue(`line_items[${index}].management`, product?.management);
      setValue(`line_items[${index}].price`, product?.price);
      //DOAR LE PUN AICI SA FIE GOALE CA SUNT OBLIGATORII
      setValue(`cart_tax`, "");
      setValue(`shipping_tax`, "");
      setValue(`discount_tax`, "");
      setValue(`status`, props.watch("status") ? props.watch("status") : null);
      setValue(
        `line_items[${index}].unitTVA`,
        calcTVAValue(
          product?.price,
          props.getValues(`line_items[${index}].TVA`)
        ).toFixed(2)
      );
      //Prima oara se calculeaza priceWithoutTVA din pretul de pe wordpress
      setValue(
        `line_items[${index}].priceWithoutTVA`,
        calcPriceWithoutTVA(
          product?.price,
          props.getValues(`line_items[${index}].TVA`)
        )
      );

      setValue(
        `line_items[${index}].subtotal_tax`,
        calcValue(
          props.getValues(`line_items[${index}].discount`),
          props.getValues(`line_items[${index}].quantity`),
          props.getValues(`line_items[${index}].priceWithoutTVA`)
        ).toFixed(2)
      );
      setValue(
        `line_items[${index}].total`,
        calcValueWithTVA(
          calcValue(
            props.getValues(`line_items[${index}].discount`),
            props.getValues(`line_items[${index}].quantity`),
            props.getValues(`line_items[${index}].priceWithoutTVA`)
          ),
          props.getValues(`line_items[${index}].TVA`)
        ).toFixed(2)
      );
      makeSubTotals();
    }
  };

  /**
   *
   * @param index
   * @param managementObj
   */
  const handleProductManagementChange = (index: any, managementObj: any) => {
    if (!props.getValues(`line_items[${index}].wp_product_id`)) {
      //set price and TVA from oblio_stock
      setValue(`line_items[${index}].price`, managementObj.price);
      setValue(`line_items[${index}].TVA`, managementObj.vatPercentage);
      makeSubTotals();
      // handleProductChange(
      //   null,
      //   {
      //     price: managementObj.price,
      //     TVA: managementObj.vatPercentage,
      //   },
      //   "selectOption",
      //   null
      // );
    }
  };

  /**
   *
   * @returns
   */
  const getDefaultManagement = () => {
    if (!managements || !defaultManagement) return "";
    const defaultM = managements?.find(
      (management: any) =>
        management?.management === defaultManagement?.management
    );
    return defaultM?.management || "";
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={styles.expandButton} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Grid
          container
          spacing={Config.standardGridSpacing}
          className={styles.accordionSummary}
          onClick={(event: any) => event.stopPropagation()}
        >
          {props.invoiceGeneration &&
          props.getValues(`line_items[${props.index}].name`) !== "" ? (
            <div className={styles.checkBox}>
              <Controller
                defaultValue={false}
                name={`line_items[${index}].lineChecked`}
                control={props.control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    disabled={watch(`line_items[${index}].hasBeenInvoiced`)}
                    checked={watch(`line_items[${index}].lineChecked`)}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
          ) : null}
          <Grid container className={styles.secondGrid}>
            <Grid className={styles.containerProduct}>
              <Grid item xs={12} sm={12} md={4} className={styles.productImage}>
                <img
                  width={"100%"}
                  height={"100%"}
                  src={
                    props.getValues(`line_items[${index}].image`)
                      ? props.getValues(`line_items[${index}].image`)
                      : "/missingImage.png"
                  }
                  alt=""
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                className={styles.autocompleteGrid}
              >
                <EditProduct
                  searchUrl={`${endpoints.getProductsSearch}/`}
                  control={props.control}
                  index={index}
                  getValues={props.getValues}
                  watch={props?.watch}
                  disabled={
                    props?.disabled ||
                    watch(`line_items[${index}].hasBeenInvoiced`)
                  }
                  //La Onchange se fac automat calculale asemanatoare cu cele din makeSubTotals plus popularea campurilor din billing si shipping
                  onProductChange={(e, product, reason, details) => {
                    handleProductChange(e, product, reason, details);
                  }}
                  products={props.products}
                />
              </Grid>
            </Grid>
            <Grid className={styles.detailsProduct} container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  type="number"
                  inputProps={{
                    min: "0",
                    step: "0.01",
                  }}
                  label={Vocabulary.quantity}
                  disabled={
                    props.disabled ||
                    watch(`line_items[${index}].hasBeenInvoiced`)
                  }
                  {...props.register(`line_items[${index}].quantity`)}
                  name={`line_items[${index}].quantity`}
                  id={`line_items[${index}].quantity`}
                  fullWidth
                  variant="standard"
                  onChange={(e: any) => {
                    setValue(`line_items[${index}].quantity`, e.target.value);
                    makeSubTotals();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  type="number"
                  label={Vocabulary.unitPrice}
                  disabled={
                    props.disabled ||
                    watch(`line_items[${index}].hasBeenInvoiced`)
                  }
                  {...props.register(`line_items[${index}].priceWithoutTVA`)}
                  name={`line_items[${index}].priceWithoutTVA`}
                  id={`line_items[${index}].priceWithoutTVA`}
                  fullWidth
                  variant="standard"
                  onChange={(e: any) => {
                    setValue(
                      `line_items[${index}].priceWithoutTVA`,
                      e.target.value
                    );
                    makeSubTotals();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {Vocabulary.currency}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  type="number"
                  label={Vocabulary.priveWithTVA}
                  {...props.register(`line_items[${index}].price`)}
                  name={`line_items[${index}].price`}
                  id={`line_items[${index}].price`}
                  fullWidth
                  disabled={
                    props.disabled ||
                    watch(`line_items[${index}].hasBeenInvoiced`)
                  }
                  variant="standard"
                  onChange={(e: any) => {
                    setValue(`line_items[${index}].price`, e.target.value);
                    calculateSubTotalsBasedOnPrice(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {Vocabulary.currency}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  {...props.register(`line_items[${index}].total`)}
                  name={`line_items[${index}].total`}
                  id={`line_items[${index}].total`}
                  label={Vocabulary.total}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {Vocabulary.currency}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} className={styles.accordionDetails}>
          <Grid item xs={12} sm={12} md={2.75}>
            <Controller
              name={`line_items[${index}].management`}
              defaultValue={getDefaultManagement()}
              control={props.control}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  value={field.value || " "}
                  freeSolo={false}
                  disablePortal
                  disableClearable
                  disabled
                  clearOnBlur={true}
                  options={managements}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.management === value;
                  }}
                  getOptionLabel={(option: any) =>
                    option?.management || option || ""
                  }
                  onChange={(event, value: any) => {
                    field.onChange(value.management);
                    handleProductManagementChange(index, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      value={field?.value || " "}
                      error={!!error}
                      helperText={error?.message}
                      label={Vocabulary.management}
                      {...params}
                      variant="standard"
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <TextField
              {...props.register(`line_items[${index}].subtotal_tax`)}
              name={`line_items[${index}].subtotal_tax`}
              id={`line_items[${index}].subtotal_tax`}
              label={Vocabulary.valueWithoutTVA}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {Vocabulary.currency}
                  </InputAdornment>
                ),
              }}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <TextField
              {...props.register(`line_items[${index}].discount`)}
              name={`line_items[${index}].discount`}
              id={`line_items[${index}].discount`}
              disabled={
                props.disabled || watch(`line_items[${index}].hasBeenInvoiced`)
              }
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              label={Vocabulary.discount}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              defaultValue={0}
              fullWidth
              variant="standard"
              onChange={(e: any) => {
                setValue(
                  `line_items[${index}].discount`,
                  e.target.value === "" ? 0 : e.target.value
                );
                makeSubTotals();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <TextField
              {...props.register(`line_items[${index}].discountValue`)}
              name={`line_items[${index}].discountValue`}
              id={`line_items[${index}].discountValue`}
              label={Vocabulary.discountValue}
              disabled
              fullWidth
              value={props.getValues(`line_items[${index}].discountValue`) || 0}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {Vocabulary.currency}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <TextField
              {...props.register(`line_items[${index}].TVA`)}
              name={`line_items[${index}].TVA`}
              id={`line_items[${index}].TVA`}
              label={Vocabulary.cotaTVA}
              type="number"
              disabled={
                props.disabled || watch(`line_items[${index}].hasBeenInvoiced`)
              }
              fullWidth
              onChange={(e: any) => {
                setValue(`line_items[${index}].TVA`, e.target.value);
                makeSubTotals();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <TextField
              {...props.register(`line_items[${index}].unitTVA`)}
              name={`line_items[${index}].unitTVA`}
              id={`line_items[${index}].unitTVA`}
              label={Vocabulary.unitTVA}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {Vocabulary.currency}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {!openConfirmDelete ? (
          <div className={styles.deleteButton}>
            {props.disabled ? null : (
              <Tooltip title={Vocabulary.delete}>
                <IconButton
                  disabled={watch(`line_items[${index}].hasBeenInvoiced`)}
                  aria-label="delete"
                  className={styles.deleteIcon}
                  onClick={() => setOpenConfirmDelete(!openConfirmDelete)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ) : (
          <div className={styles.deleteConfirm}>
            <IconButton
              aria-label="close"
              className={styles.closeIcon}
              onClick={() => setOpenConfirmDelete(!openConfirmDelete)}
            >
              <CloseIcon />
            </IconButton>

            <IconButton
              aria-label="confirmDelete"
              className={styles.confirmDelete}
              onClick={() => {
                deleteLine(index);
                setOpenConfirmDelete(!openConfirmDelete);
              }}
            >
              <Typography>{Vocabulary.confirmDelete}</Typography>
            </IconButton>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
