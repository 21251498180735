import { createTheme } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
    save?: Palette["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
    pastelRed?: Palette["primary"];
    tertiary?: Palette["primary"];
  }

  interface ThemeOptions {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
    save?: Palette["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
    pastelRed?: Palette["primary"];
    tertiary?: Palette["primary"];
  }

  interface Palette {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
    save?: Palette["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
    pastelRed?: Palette["primary"];
    tertiary?: Palette["primary"];
  }
  interface PaletteOptions {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
    save?: PaletteOptions["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
    pastelRed?: Palette["primary"];
    tertiary?: Palette["primary"];
  }

  interface PaletteColor {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
    save?: PaletteOptions["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
    pastelRed?: Palette["primary"];
    tertiary?: Palette["primary"];
  }

  interface SimplePaletteColorOptions {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
    save?: PaletteOptions["primary"];
    redColor?: Palette["primary"];
    purpleColor?: Palette["primary"];
    blueGrey?: Palette["primary"];
    purpleGrey?: Palette["primary"];
    defaultBorder?: Palette["primary"];
    pastelRed?: Palette["primary"];
    tertiary?: Palette["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    header: true;
    dashboard: true;
    cancel: true;
    save: true;
    blueGrey: true;
    purpleGrey: true;
    iconDefault: true;
    defaultBorder: true;
    pastelRed: true;
    tertiary: true;
  }
}

declare module "@mui/material/Fab" {
  interface FabPropsColorOverrides {
    header: true;
    dashboard: true;
    cancel: true;
    save: true;
    blueGrey: true;
    purpleGrey: true;
    pastelRed: true;
    tertiary: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    header: true;
    dashboard: true;
    cancel: true;
    save: true;
    blueGrey: true;
    purpleGrey: true;
    pinkColor: true;
    iconDefault: true;
    pastelRed: true;
    tertiary: true;
  }
}

const light = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#009846",
      light: "#88aa91",
      dark: "#0e441b",
      contrastText: "#fff",
    },
    dashboard: {
      main: "#000000",
    },
    defaultBorder: {
      main: "#808080",
      dark: "	#404040",
      light: "#BEBEBE",
      contrastText: "#fff",
    },
    secondary: {
      main: "#3b3839",
    },
    tertiary: {
      main: "#bfdc71",
      dark: "#8dbf4d",
      light: "#e0ff99",
      contrastText: "#000",
    },
    cancel: {
      main: "#D8D8D8",
    },
    save: {
      main: "#ACE1AF",
    },
    header: {
      main: "#7D8FB3",
      contrastText: "#fff",
    },
    successColor: {
      main: "#12A152",
    },
    errorColor: {
      main: "#f25",
    },
    textColor: {
      main: "#000000",
    },
    textColorSecondary: {
      main: "#ffffff",
    },
    iconDefault: {
      main: "#455a64",
    },
    iconBlue: {
      main: "#2196f3",
    },
    blueColor: {
      main: "#8090B5",
    },
    greenColor: {
      main: "#6DA77C",
    },
    pinkColor: {
      main: "#FFB0CB",
    },
    yellowColor: {
      main: "#F9F871",
    },
    orangeColor: {
      main: "#FFD381",
    },
    blueGrey: {
      light: blueGrey[300],
      main: blueGrey[500],
      dark: blueGrey[700],
      contrastText: "#fff",
    },
    pastelRed: {
      main: "#ff6666",
      light: "#ff9999",
      dark: "#ff3333",
      contrastText: "#fff",
    },
    purpleGrey: {
      light: "#c9b2bd",
      main: "#b291a2",
      dark: "#7c6571",
      contrastText: "#fff",
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: ["Roboto"].join(","),
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
  },
  direction: "ltr",
});

export default light;
